td {
  font-size: 12px;
  padding: 5px !important;
  margin: 2px !important;
}

th {
  font-size: 12px;
  padding: 5px !important;
  margin: 2px !important;
}