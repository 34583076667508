@font-face {
  font-family: 'Photograh Signature';
  src: local('Photograh Signature'), url(./fonts/photograph_signature.ttf) format('truetype');
}

.handwritten-title {
  font-family: "Droid Sans" , Verdana, Arial, serif;
  font-weight: black;
}

.amoretti-logo {
  width: 200px;
  height: 200px;
  text-align: center;
}

.amoretti-logo-checkin {
  width: 100px;
  height: 100px;
  text-align: center;
}

body {
  /* background: linear-gradient(
      to right,
      white 0%,
      white 75%,
      black 75%,
      black 100%
  ); */
}

.white-bg {
  background-color: white;
}

.checkin-container {
    height: 1000px;
}